'use client'
import { AccountCircle, Menu as MenuIcon, Notifications } from "@mui/icons-material";
import { AppBar, Badge, Box, Button, Card, CardActionArea, CardContent, Divider, IconButton, Menu, MenuItem, Stack, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { clearUserNotification, getUserNewNotifications, getUserNotifications } from "../lib/firebase/database";

export default function Header() {
  const { user, loading, signOut } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNot, setAnchorElNot] = useState(null);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState(0);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuNot = (event) => {
    if(newNotifications>0){
      setNewNotifications(0);
      clearUserNotification(user.uid);
    }
    setAnchorElNot(event.currentTarget);
  };

  const handleCloseNot = () => {
    setAnchorElNot(null);
  };

  const handleMenuNav = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNav = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    handleClose();
    signOut();
  }

  const handleNavigation = (path) => {
    handleClose();
    handleCloseNav();
    navigate(path);
  }
  const handleNotificationClick = (externalLink, link) => {
    handleCloseNot();
    if(externalLink){
      window.open(link, "_blank");
    }else{
      navigate(link);
    }
  }

  const menuItems = [
    {
      title: "Início",
      link: "/"
    },
    {
      title: "Cursos",
      link: "/cursos"
    }
  ];

  useEffect(()=>{
    const fetchData = async () =>{
      const userNotificationsData = await getUserNotifications(user.uid);
      if(userNotificationsData){
        setNotifications(Object.values(userNotificationsData));
      }

      const userNotificationsCount = await getUserNewNotifications(user.uid);
      if(userNotificationsCount){
        setNewNotifications(userNotificationsCount);
      }
    }
    if(user){
      fetchData();
    }

  },[user])
  
  return (
    <Box sx={{ maxWidth: '100%' }}>
      <AppBar position="fixed">
        <Toolbar>
          <Link style={{ textDecoration: 'none' }} to="/">
            <Typography
              variant="h6"
              color="primary"              
              noWrap              
              sx={{ mr: 2, fontWeight:'700', textDecoration: 'none !important' }}
            >
              FUTEBOL CURSOS
            </Typography>
          </Link>  
          
          <Box sx={{ display:{xs:'none',sm:'flex'} }}>
            {menuItems.map((item,index)=>(
              <Link key={index} to={item.link}>
                <Button size="small" sx={{ color: 'white'}}>{item.title}</Button>
              </Link>
            ))}            
          </Box>
          
          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display:{xs:'flex',sm:'none'} }}>
            <IconButton
              size="large"
              onClick={handleMenuNav}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-navigation"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNav}
            >
              {menuItems.map((item,index)=>(
                <MenuItem key={index} onClick={()=>{handleNavigation(item.link)}}>{item.title}</MenuItem>
              ))}
            </Menu>
          </Box>

          {user ? (
            <div>
              <IconButton
                size="large"
                onClick={handleMenuNot}
                color="inherit"
              >
                <Badge color="error" badgeContent={newNotifications} max={99}>
                  <Notifications />
                </Badge>
              </IconButton>

              <Menu
                id="notifications"
                anchorEl={anchorElNot}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElNot)}
                onClose={handleCloseNot}
              >
                <Stack spacing={1}>

                  {notifications.length > 0 ? notifications.map((notification,index)=>(
                    <Card sx={{ maxWidth: 300 }} key={index}>
                      <CardActionArea onClick={()=>{handleNotificationClick(notification.externalLink,notification.link)}}>
                        <CardContent>
                          <Typography variant="caption">
                            {notification.title}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            {notification.message}
                          </Typography>
                        </CardContent>
                      </CardActionArea>                    
                    </Card>
                  )) : (
                    <Card sx={{ maxWidth: 300 }}>
                      <CardContent>
                        <Typography variant="caption">
                          Tudo em dia!
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                          Sem novas notificações.
                        </Typography>
                      </CardContent>
                    </Card>
                  )}

                </Stack>
                
              </Menu>


              <IconButton
                size="large"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={()=>{handleNavigation("/conta")}}>Minha Conta</MenuItem>
                <MenuItem onClick={()=>{handleNavigation("/compras")}}>Compras</MenuItem>
                <MenuItem onClick={()=>{handleNavigation("/bonus")}}>Meus Bônus</MenuItem>
                <Divider />                
                <MenuItem onClick={()=>{handleNavigation("/ajuda")}}>Preciso de Ajuda</MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout}>Sair</MenuItem>
              </Menu>
            </div>
          ) : (
            !loading && (
              <Button size="small" onClick={()=>{handleNavigation("/entrar")}} disableElevation variant="contained">Entrar</Button>
            )
          )}       
        </Toolbar>
      </AppBar>
      <Box sx={{ height: {xs:'56px', sm:'64px'} }} />
    </Box>
  );
}