import { Box, Grid, Typography } from "@mui/material"
import { Link } from "react-router-dom"

const Footer = () => {

    const menuItems = [
        {title: "Página Principal", href: "/"},
        {title: "Todos os Cursos", href: "/cursos"},
        {title: "Contato (WhatsApp)", href: "https://api.whatsapp.com/send?phone=5512997125153", target: "_blank"}
    ]

    const menu2Items = [
        {title: "Minha Conta", href: "/conta"},
        {title: "Minhas Compras", href: "/compras"},
        {title: "Meus Bônus", href: "/bonus"}
    ]

    return(        
        <Box sx={{ py:3, pb:2, flexGrow: 1, display:'flex', justifyContent: 'center', background:"#000000" }}>
            <Grid container>
                <Grid item xs={12} sm={4} sx={{p:3,display:'flex',flexDirection:'column', alignItems:'center'}}>
                    {menuItems.map((item,index)=>(
                        <Link key={index} to={item.href} style={{textDecoration: "none"}}>
                            <Typography variant="caption" color="GrayText">
                                {item.title}
                            </Typography>
                        </Link>
                    ))}                    
                </Grid>
                
                <Grid item xs={12} sm={4} sx={{p:3,display:'flex',flexDirection:'column', alignItems:'center'}}>
                    {menu2Items.map((item,index)=>(
                        <Link key={index} to={item.href} style={{textDecoration: "none"}} target={item.target ? item.target : ""} >
                            <Typography variant="caption" color="GrayText">
                                {item.title}
                            </Typography>
                        </Link>
                    ))}                    
                </Grid>

                <Grid item xs={12} sm={4} sx={{p:3,display:'flex',flexDirection:'column', alignItems:'center'}}>
                    <Typography variant="caption" color="GrayText">
                        <b>Futebol Cursos</b>
                    </Typography>
                    <Typography variant="caption" color="GrayText">
                        <b>CNPJ</b> 33.702.532/0001-20
                    </Typography>
                    <Typography variant="caption" color="GrayText">
                        <b>IM</b> 09279245
                    </Typography>
                    <Typography variant="caption" color="GrayText">
                        contato@futebolcursos.com
                    </Typography>

                </Grid>
                <Grid item xs={12} sx={{pt:3,display:'flex',flexDirection:'column', alignItems:'center'}}>
                    <Typography variant="caption" color="GrayText">
                        © 2018-2024 Futebol Cursos - Todos os direitos reservados.
                    </Typography>
                </Grid>
            </Grid>
            
        </Box>
    )
}

export default Footer;