'use client'
import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
    onAuthStateChanged, 
    signOut as firebaseSignOut, 
    signInWithGoogle as firebaseSignInWithGoogle,
    signInWithEmail as firebaseSignInWithEmail,
    createUserWithEmail as firebaseCreateUserWithEmail,
    sendPasswordReset as sendPasswordResetEmail,
 } from '../lib/firebase/auth';

 import { logEvent } from "firebase/analytics";
 import { analytics } from '../lib/firebase/firebase';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(user => {
            setUser(user);
            setLoading(false);
            if(user){
                logEvent(analytics, 'login');
            }
        });
        return () => unsubscribe();
    }, []);

    const signInWithGoogle = async () => {
        await firebaseSignInWithGoogle();
    };

    const signInWithEmail = async (email, password, setError) => {
        await firebaseSignInWithEmail(email, password, setError);
    };

    const createUserWithEmail = async (name, email, password, setError) =>{
        await firebaseCreateUserWithEmail(name, email, password, setError);
    }

    const sendPasswordReset = async (email) => {
        await sendPasswordResetEmail(email);
    }

    const signOut = async () => {
        await firebaseSignOut();
    };

    const value = {
        user,
        loading,
        signInWithGoogle,
        signOut,
        signInWithEmail,
        createUserWithEmail,
        sendPasswordReset,
    };

    return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};
