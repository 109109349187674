import { get, increment, limitToLast, push, query, ref, set, update } from "firebase/database";
import { database } from "./firebase";
import { checkExpiration } from "../util/util"



/*
USUÁRIOS
*/

export async function checkCourseAccess(userId, courseSlug){
    try{
        const dbRef = ref(database, `/users/${userId}/courses/${courseSlug}/expiration`);
        return await get(dbRef).then((snapshot)=>{
            if(snapshot.exists()){
                return checkExpiration(snapshot.val());
            }else{                
                return false;
            }
        }) 
    }catch (error){
        console.error("Erro requisitando acesso do usuário", error);
        return false;
    }
}

export async function getUserPublicInfo(userUid){
    try{
        const dbRef = ref(database, `/users/${userUid}/public`);
        return await get(dbRef).then((snapshot)=>{
            if(snapshot.exists()){
                return snapshot.val();
            }else{
                //console.log("Usuário não encontrado:", userUid);
                return null;
            }
        })
    }catch (error){
        console.error("Erro ao chamar getUserPublicInfo().", error);
        return null;
    }
}

export async function getUserPrivateData(userUid){
    try{
        const dbRef = ref(database, `/users/${userUid}/private`);
        return await get(dbRef).then((snapshot)=>{
            if(snapshot.exists()){
                return snapshot.val();
            }else{
                //console.log("Usuário não encontrado:", userUid);
                return null;
            }
        })
    }catch (error){
        console.error("Erro ao chamar getUserPrivateData().", error);
        return null;
    }
}

export async function setUserData(userUid,data){
    try{
        const dbRef = ref(database, `/users/${userUid}`);        
        await update(dbRef,data);
        return true;
    }catch (error){
        console.error("Erro ao chamar setUserPersonalData().", error);
        return null;
    }
}

export async function getUserLessonProgress(userId, contentSlug){
    try{
        const dbRef = ref(database, `/users/${userId}/contents/${contentSlug}/progress`);
        return await get(dbRef).then((snapshot)=>{
            if(snapshot.exists()){
                return snapshot.val();
            }else{                
                return 0;
            }
        })
    }catch (error){
        console.error("Erro requisitando getUserLessonProgress", error);
        return false;
    }
}

export async function setUserLessonProgress(userId, contentSlug, progress){
    try{
        const dbRef = ref(database, `/users/${userId}/contents/${contentSlug}`);
        return await set(dbRef,{progress: progress});
    }catch (error){
        console.error("Erro requisitando getUserLessonProgress", error);
        return false;
    }
}

export async function getUserBonus(userId){
    try{
        const dbRef = ref(database, `/users/${userId}/bonus`);
        return await get(dbRef).then((snapshot)=>{
            if(snapshot.exists()){
                return snapshot.val();
            }else{                
                return null;
            }
        })        
    }catch (error){
        console.error("Erro ao chamar getUserBonus().", error);
        return null;
    }
}

export async function getUserCourses(userId){
    try{
        const dbRef = ref(database, `/users/${userId}/courses`);
        return await get(dbRef).then((snapshot)=>{
            if(snapshot.exists()){
                return Object.keys(snapshot.val());
            }else{                
                return [];
            }
        })        
    }catch (error){
        console.error("Erro ao chamar getUserCourses().", error);
        return null;
    }
}

/*
CURSOS
*/

export async function getCourseList(){
    try{
        const dbRef = ref(database, `/courses/coursesList`);
        return await get(dbRef).then((snapshot)=>{
            if(snapshot.exists()){
                return Object.keys(snapshot.val());
            }else{
                console.log("/courses/coursesList Lista de Cursos não encontrada");
                return null;
            }
        })        
    }catch (error){
        console.error("Erro ao chamar getCourseList().", error);
        return null;
    }
}

export async function getCourseProductHero(courseSlug){
    try{
        const dbRef = ref(database, `/courses/coursesData/${courseSlug}/courseProductHero`);
        return await get(dbRef).then((snapshot)=>{
            if(snapshot.exists()){
                return {...snapshot.val(),courseId: courseSlug};
            }else{
                console.log("/courseProductHero do Curso não encontrado:", courseSlug);
                return null;
            }
        })        
    }catch (error){
        console.error("Erro ao chamar getCourseProductHero(courseslug).", error);
        return null;
    }
}

export async function getCoursePage(courseSlug){
    try{
        const dbRef = ref(database, `/courses/coursesData/${courseSlug}/coursePage`);
        return await get(dbRef).then((snapshot)=>{
            if(snapshot.exists()){
                return snapshot.val();
            }else{
                console.log("/coursePage do Curso não encontrado:", courseSlug);
                return null;
            }
        })
    }catch (error){
        console.error("Erro ao chamar getCoursePage(courseSlug).", error);
        return null;
    }
}

export async function getCourseProgress(userId, courseId){
    try{
        const dbRefCourse = ref(database, `/courses/coursesData/${courseId}/coursePage/courseContent`);
        const dbRefUser = ref(database, `/users/${userId}/contents`);
        let courseContent;
        let userProgress;
        let totalProgress = 0;
        let totalItems = 0;

        await get(dbRefCourse).then((snapshot)=>{
            if(snapshot.exists()){                
                courseContent = snapshot.val();
            }
        })

        await get(dbRefUser).then((snapshot)=>{
            if(snapshot.exists()){                
                userProgress = snapshot.val();
            }else{
                userProgress = [];
            }
        })

        courseContent.forEach(modulo => {
            modulo.items.forEach(aula => {
                totalItems++;
                if (userProgress[aula] && userProgress[aula].progress === 1) {
                    totalProgress++;
                }
            });
        });
        
        return totalItems === 0 ? 0 : totalProgress / totalItems;

    }catch (error){
        console.error("Erro ao chamar getCourseProgress().", error);
        return null;
    }
}

export async function getCourseFirstIncompletedContent(userId, courseId){
    try{
        const dbRefCourse = ref(database, `/courses/coursesData/${courseId}/coursePage/courseContent`);
        const dbRefUser = ref(database, `/users/${userId}/contents`);
        let courseContent;
        let userProgress;

        await get(dbRefCourse).then((snapshot)=>{
            if(snapshot.exists()){                
                courseContent = snapshot.val();
            }
        })

        await get(dbRefUser).then((snapshot)=>{
            if(snapshot.exists()){                
                userProgress = snapshot.val();
            }else{
                userProgress = [];
            }
        })

        for (let i = 0; i < courseContent.length; i++) {
            const modulo = courseContent[i];
            for (let j = 0; j < modulo.items.length; j++) {
                const aula = modulo.items[j];
                if (!userProgress[aula] || userProgress[aula].progress !== 1) {
                    return aula;
                }
            }
        }

        return null;

    }catch (error){
        console.error("Erro ao chamar getCourseFirstIncompletedContent().", error);
        return null;
    }
}



/*
CONTEÚDOS
*/

export async function getLessonPage(lessonSlug){
    try{
        const dbRef = ref(database, `/contents/contentsData/${lessonSlug}`);
        return await get(dbRef).then((snapshot)=>{
            if(snapshot.exists()){
                return snapshot.val();
            }else{
                console.log("Aula não encontrada:", lessonSlug);
                return null;
            }
        })
    }catch (error){
        console.error("Erro ao chamar getLessonPage(lessonSlug).", error);
        return null;
    }
}

export async function getContentCard(courseSlug,contentSlug,contentIndex){
    try{
        const dbRefImageUrl = ref(database, `/contents/contentsData/${contentSlug}/accessPrompt/imageUrl`);
        const dbRefTitle = ref(database, `/contents/contentsData/${contentSlug}/accessPrompt/title`);
        let imageUrl;
        let title;
        let parent = `Aula ${contentIndex + 1}`;

        await get(dbRefImageUrl).then((snapshot)=>{
            if(snapshot.exists()){                
                imageUrl = snapshot.val();
            }
        })

        await get(dbRefTitle).then((snapshot)=>{
            if(snapshot.exists()){                
                title = snapshot.val();
            }
        })

        const newCard = {
            imageUrl: imageUrl, 
            parent: parent, 
            title:  title, 
            href: `/cursos/${courseSlug}/${contentSlug}`
        }

        return newCard;

    }catch (error){
        console.error("Erro ao chamar getContentCard().", error);
        return null;
    }
}

export async function getContentComments(contentSlug){
    try{
        const dbRef = ref(database, `/contents/contentsData/${contentSlug}/comments`);
        return await get(dbRef).then((snapshot)=>{
            if(snapshot.exists()){
                return snapshot.val();
            }else{
                //console.log("Aula não encontrada:", contentSlug);
                return null;
            }
        })
    }catch (error){
        console.error("Erro ao chamar getContentComments().", error);
        return null;
    }
}

export async function addContentComments(courseId,contentSlug,parentUid,comment,userUid){
    try{
        const title = await getContentTitle(contentSlug);
        const link = `/cursos/${courseId}/${contentSlug}`;
        const adminMessage = "Novo comentário adicionado.";
        addUserNotification(65,title,adminMessage,link);
        if(parentUid){
            const dbRefParentUser = ref(database, `/contents/contentsData/${contentSlug}/comments/${parentUid}/userUid`);
            await get(dbRefParentUser).then(async(snapshot)=>{
                if(snapshot.val()){
                    const userMessage = "Seu comentário recebeu uma nova resposta.";
                    addUserNotification(snapshot.val(),title,userMessage,link);
                }
            })

            const dbRef = ref(database, `/contents/contentsData/${contentSlug}/comments/${parentUid}/replies`);
            const newComment = {
                comment: comment,
                userUid: userUid,
            }
            return await push(dbRef,newComment);
        }else{
            const dbRef = ref(database, `/contents/contentsData/${contentSlug}/comments/`);
            const newComment = {
                comment: comment,
                userUid: userUid,
            }
            return await push(dbRef,newComment);
        }
    }catch (error){
        console.error("Erro ao chamar addContentComments().", error);
        return null;
    }
}

export async function getContentLinks(courseSlug,contentSlug){
    
    try{
        const dbRefModules = ref(database, `/courses/coursesData/${courseSlug}/coursePage/courseContent`);
        return await get(dbRefModules).then((snapshot)=>{
            if(snapshot.exists()){                

                const modules = snapshot.val();
                let moduleIndex = null;
                let contentIndex = null;
                
                for (let moduleIndexTemp = 0; moduleIndexTemp < modules.length; moduleIndexTemp++) {
                    const modulo = modules[moduleIndexTemp];
                    const contents = modulo.items;
                    for (let contentIndexTemp = 0; contentIndexTemp < contents.length; contentIndexTemp++) {
                        if (contents[contentIndexTemp] === contentSlug) {                            
                            moduleIndex = moduleIndexTemp;
                            contentIndex = contentIndexTemp;
                        }
                    }
                }
                
                const modulo = modules[moduleIndex];
                const items = modulo.items;
                let prevLink = null;
                let nextLink = null;
                let courseLink = `/cursos/${courseSlug}`;

                if (contentIndex >= 0 && contentIndex < items.length) {                    

                    if (contentIndex === 0) {                        
                        if (moduleIndex > 0) {
                            const moduloAnterior = modules[moduleIndex - 1];
                            const aulasModuloAnterior = moduloAnterior.items;                            
                            prevLink = `/cursos/${courseSlug}/${aulasModuloAnterior[aulasModuloAnterior.length - 1]}`;
                        }
                    } else {                        
                        prevLink = `/cursos/${courseSlug}/${items[contentIndex - 1]}`;
                    }
                    
                    if (contentIndex === items.length - 1) {                        
                        if (moduleIndex < modules.length - 1) {                            
                            nextLink = `/cursos/${courseSlug}/${modules[moduleIndex + 1].items[0]}`;
                        }
                    } else {                        
                        nextLink = `/cursos/${courseSlug}/${items[contentIndex + 1]}`;
                    }

                    return { prevLink, courseLink, nextLink };

                } else {
                    console.log("Índice de aula inválido");
                    return null;
                }
            }
        })
    }catch (error){
        console.error("Erro ao executar getContentLinks().",error);
        return null;
    }
}

export async function getContentTitle(contentSlug){
    try{
        const dbRef = ref(database, `/contents/contentsData/${contentSlug}/accessPrompt/title`);
        return await get(dbRef).then((snapshot)=>{
            if(snapshot.exists()){
                return snapshot.val();
            }else{
                //console.log("Aula não encontrada:", contentSlug);
                return null;
            }
        })
    }catch (error){
        console.error("Erro ao chamar getContentTitle().", error);
        return null;
    }
}


/*
PRODUTOS
*/

export async function getProducts(){
    try{
        const dbRef = ref(database, `/products/`);
        return await get(dbRef).then((snapshot)=>{
            if(snapshot.exists()){
                return Object.values(snapshot.val());
            }else{                
                return null;
            }
        })
    }catch (error){
        console.error("Erro ao chamar getProducts().", error);
        return null;
    }
}

export async function getProduct(productId){
    try{
        const dbRef = ref(database, `/products/${productId}`);
        return await get(dbRef).then((snapshot)=>{
            if(snapshot.exists()){
                return snapshot.val();
            }else{
                //console.log("Aula não encontrada:", contentSlug);
                return null;
            }
        })
    }catch (error){
        console.error("Erro ao chamar getProduct().", error);
        return null;
    }
}

export async function getBonusHero(bonusId){
    try{
        const dbRef = ref(database, `/bonus/bonusData/${bonusId}`);
        return await get(dbRef).then((snapshot)=>{
            if(snapshot.exists()){
                return {...snapshot.val(),bonusId: bonusId};
            }else{
                console.log("Bônus não encontrado:", bonusId);
                return null;
            }
        })        
    }catch (error){
        console.error("Erro ao chamar getBonusHero().", error);
        return null;
    }
}



/*
COMPRAS
*/


export async function getOrders(userUid){
    try{
        const dbRef = ref(database, `/users/${userUid}/orders`);
        return await get(dbRef).then((snapshot)=>{
            if(snapshot.exists()){
                return snapshot.val();
            }else{                
                return null;
            }
        })        
    }catch (error){
        console.error("Erro ao chamar getOrders().", error);
        return null;
    }
}



/*
NOTIFICAÇÕES PARA USUÁRIOS
*/


export async function addUserNotification(userUid,title,message,link){
    try{

        const dbRefCount = ref(database);
        const updates = {};
        updates[`/users/${userUid}/notifications/notificationsCount`] = increment(1);
        update(dbRefCount, updates);

        const dbRef = ref(database, `/users/${userUid}/notifications/notificationsList`);
        const newNotification = {
            title: title,
            message: message,
            link: link
        }
        return await push(dbRef,newNotification);
    }catch (error){
        console.error("Erro ao chamar addUserNotification().", error);
        return null;
    }
}

export async function clearUserNotification(userUid){
    try{

        const dbRef = ref(database, `/users/${userUid}/notifications`);
        const notificationsCount = {
            notificationsCount: 0
        }
        return await update(dbRef,notificationsCount);
    }catch (error){
        console.error("Erro ao chamar clearUserNotification().", error);
        return null;
    }
}

export async function getUserNotifications(userUid){
    try{
        const dbRef = ref(database, `/users/${userUid}/notifications/notificationsList`);
        
        const dataQuery = query(dbRef, limitToLast(10));
        return await get(dataQuery).then((snapshot)=>{
            if(snapshot.val()){
                const data = snapshot.val();
                const dataArray = Object.entries(data).map(([key, value]) => ({ key, ...value }));
                dataArray.sort((a, b) => b.key.localeCompare(a.key));
                return dataArray;
            }else{                
                return null;
            }
        })        
    }catch (error){
        console.error("Erro ao chamar getUserNotifications().", error);
        return null;
    }
}

export async function getUserNewNotifications(userUid){
    try{
        const dbRef = ref(database, `/users/${userUid}/notifications/notificationsCount`);
        return await get(dbRef).then((snapshot)=>{
            if(snapshot.exists()){
                return snapshot.val();
            }else{                
                return 0;
            }
        })
    }catch (error){
        console.error("Erro ao chamar getUserNewNotifications().", error);
        return null;
    }
}