import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../lib/firebase/firebase";

const FirebaseAnalytics = () => {
    const location = useLocation();
    
    useEffect(() => {
        const handleTitleChange = () => {
            const pageTitle = document.title;
            logEvent(analytics, 'page_view', { page_title: pageTitle, page_path: location.pathname });
        };

        // Adicione um listener para monitorar mudanças no título da página
        const titleObserver = new MutationObserver(handleTitleChange);
        titleObserver.observe(document.querySelector('title'), { subtree: true, characterData: true, childList: true });

        // Certifique-se de chamar handleTitleChange uma vez no carregamento inicial
        handleTitleChange();

        // Retorne uma função de limpeza para remover o observador quando o componente for desmontado
        return () => {
            titleObserver.disconnect();
        };
    }, []);

    return null;
}

export default FirebaseAnalytics