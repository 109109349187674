import { getAnalytics } from "firebase/analytics";
import { getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyCcU31LdXUq25lgX2Q1oh6rnZu4glVdCgg",
    //authDomain: "futebol-cursos.firebaseapp.com",
    authDomain: "futebolcursos.com",
    databaseURL: "https://futebol-cursos-default-rtdb.firebaseio.com",
    projectId: "futebol-cursos",
    storageBucket: "futebol-cursos.appspot.com",
    messagingSenderId: "870086428783",
    appId: "1:870086428783:web:2ae798895e80b557fa659a",
    measurementId: "G-R0R14RRB4F"
};

export const firebaseApp = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
export const auth = getAuth(firebaseApp);
export const database = getDatabase(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
