import React, { Suspense } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import { AuthProvider } from './context/AuthContext';

import { darkTheme } from './theme/theme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline'

import Header from './components/Header';
import Footer from './components/Footer';
import Loader from './components/Loader';
import FirebaseAnalytics from "./components/FirebaseAnalytics";

const Home = React.lazy(() => import("./pages/inicio/page"));
const Entrar = React.lazy(() => import("./pages/entrar/page"));

const Ajuda = React.lazy(() => import("./pages/ajuda/page"));
const Bonus = React.lazy(() => import("./pages/bonus/page"));
const Conta = React.lazy(() => import("./pages/conta/page"));
const Compras = React.lazy(() => import("./pages/compras/page"));

const Cursos = React.lazy(() => import("./pages/cursos/page"));
const Curso = React.lazy(() => import("./pages/cursos/course/page"));
const Conteudo = React.lazy(() => import("./pages/cursos/course/content/page"));
const Trial = React.lazy(() => import("./pages/trial/page"));

const Certificado = React.lazy(() => import("./pages/certificado/page"));

const Comprar = React.lazy(() => import("./pages/comprar/page"));
const StatusStripe = React.lazy(() => import("./pages/comprar/stripe/status/page"));

const Filtrar = React.lazy(() => import("./pages/filtrar/page"));

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Router>    
          <FirebaseAnalytics />      
          <Header />
          <Suspense fallback={<Loader />}>
            
            <Routes>

              <Route path="/filtrar" element={<Filtrar />} />

              <Route path="/" element={<Home />} />
              <Route path="/entrar" element={<Entrar />} />
              
              <Route path="/ajuda" element={<Ajuda />} />
              <Route path="/bonus" element={<Bonus />} />
              <Route path="/conta" element={<Conta />} />
              <Route path="/compras" element={<Compras />} />

              <Route path="/cursos" element={<Cursos />} />
              <Route path="/cursos/:courseId" element={<Curso />} />
              <Route path="/cursos/:courseId/:contentId" element={<Conteudo />} />
              <Route path="/trial/:courseId" element={<Trial />} />

              <Route path="/certificado/:courseId/:userId" element={<Certificado />} />

              <Route path="/comprar/:productId" element={<Comprar />} />
              <Route path="/comprar/:productId/stripe/status" element={<StatusStripe />} />

            </Routes>

          </Suspense>
          <Footer />
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;