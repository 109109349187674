import createTheme from '@mui/material/styles/createTheme';

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});